import { Vue, Component } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BModal,
  BForm,
  BCardText,
  BImg,
  BBadge
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import { ListVue, listVueMixin } from '@/utility/pagination/list-vue.mixin';
import Pagination from '@/@core/components/paginations/Pagination.vue';
import { BaseStatus, SuppliesCategoryViewModel } from '@/api/api';
import {
  suppliesCategoryListDispatcher,
  SuppliesCategoryListInput,
  suppliesCategoryListNamespace
} from './store/SuppliesCategory-list.module';
import {
  ListGetter,
  ListGetterTypes
} from '@/utility/pagination/get-list.module-type';
import ApiClientFactory from '@/api/apiClientFactory';
import { DEFAULT_PAGE_SIZE } from '@/utility/pagination/pagination';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { enumToDropdownOptions } from '@/utility/utils';
import ProcessingSettingModal from '@/components/processingSetting/ProcessingSettingModal.vue';
import SuppliesCategoryFormModal from '../suppliesCategoryForm/SuppliesCategoryFormModal.vue';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    BCardText,
    Pagination,
    BImg,
    BBadge,
    SuppliesCategoryFormModal,
    ProcessingSettingModal
  },
  mixins: [
    listVueMixin({
      initialInput: {
        itemsPerPage: DEFAULT_PAGE_SIZE,
        searchText: ''
      },
      dispatcher: suppliesCategoryListDispatcher,
      debouncedWatchers: [(listInput) => listInput.searchText]
    })
  ]
})
export default class SuppliesCategoryList
  extends Vue
  implements ListVue<SuppliesCategoryViewModel, SuppliesCategoryListInput> {
  @suppliesCategoryListNamespace.Getter(ListGetter.state)
  readonly listState!: ListGetterTypes<
    SuppliesCategoryViewModel,
    SuppliesCategoryListInput
  >[ListGetter.state];

  VUE_APP_API_BASE_HOST = process.env.VUE_APP_API_BASE_HOST;
  SUPPLIES_CATEGORY_STATUS_ENUM = BaseStatus;
  $refs!: {
    suppliesCategoryFormModal: InstanceType<typeof SuppliesCategoryFormModal>;
  };

  suppliesCategoryStatusOptions: DropdownOption[] = enumToDropdownOptions(
    BaseStatus
  );

  displaySuppliesCategoryStatus(value) {
    return this.suppliesCategoryStatusOptions.find((x) => x.value === value)
      ? this.$t(
          this.suppliesCategoryStatusOptions.find((x) => x.value === value)
            ?.text as string
        ).toString()
      : '';
  }

  mounted() {
    suppliesCategoryListDispatcher.load();
  }

  edit(id: number) {
    this.$refs.suppliesCategoryFormModal.openEditModal(id);
  }

  remove(id: number) {
    const client = new ApiClientFactory().suppliesCategoryClient();
    client.delete(id).then(() => {
      suppliesCategoryListDispatcher.load();
      // show toast
      this.$bvToast.toast('Xóa danh mục vật tư thành công', {
        title: 'Danh mục vật tư',
        toaster: 'b-toaster-bottom-right',
        variant: 'success'
      });
    });
  }

  // OpenProductMenuSetting() {
  //   this.$router.push({ name: PRODUCT_MENU_SETTING_ROUTE_NAME });
  // }

  headers = [
    {
      label: 'STT',
      field: 'orderNumber',
      sortable: false,
      width: '3%'
    },
    {
      label: 'Tên',
      field: 'name'
    },
    {
      label: this.$t('Status').toString(),
      field: 'status',
      width: '20%'
    },
    {
      label: '',
      field: '_action',
      sortable: false,
      width: '1%'
    }
  ];
}
