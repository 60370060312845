import { getDispatch } from '../get-dispatch';
import { DispatchAction } from '../utility/dispatch-action';
import {
  SuppliesCategoryActions,
  SUPPLIES_CATEGORY_STATE_NAMESPACE
} from './suppliesCategory.module-types';

export const dispatchSuppliesCategoryAction: DispatchAction<SuppliesCategoryActions> = getDispatch(
  SUPPLIES_CATEGORY_STATE_NAMESPACE
);
