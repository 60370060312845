import { Vue, Component, Watch } from 'vue-property-decorator';
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormFile,
  BFormTextarea,
  BFormSelect,
  BImg,
  BFormSelectOption,
  BFormCheckbox,
  BListGroup,
  BListGroupItem
} from 'bootstrap-vue';
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full';
import {
  FileParameter,
  ProductCategoryOrderNumber,
  BaseStatus,
  SuppliesCategoryViewModel,
  UpdateSuppliesCategoryOrderNumbers
} from '@/api/api';
import ApiClientFactory from '@/api/apiClientFactory';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { enumToDropdownOptions } from '@/utility/utils';
import CustomLabel from '@/@core/components/labels/CustomLabel.vue';
import draggable from 'vuedraggable';
import {
  SuppliesCategoryAction,
  SuppliesCategoryGetter,
  SuppliesCategoryNamespace
} from '@/store/suppliesCategory/suppliesCategory.module-types';
import { dispatchSuppliesCategoryAction } from '@/store/suppliesCategory/suppliesCategory.dispatch';
import { suppliesCategoryListDispatcher } from '../suppliesCategoryList/store/SuppliesCategory-list.module';

@Component({
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BModal,
    BForm,
    BFormFile,
    BFormTextarea,
    BFormSelect,
    BImg,
    ValidationProvider,
    ValidationObserver,
    CustomLabel,
    BFormSelectOption,
    BFormCheckbox,
    draggable,
    BListGroup,
    BListGroupItem
  }
})
export default class SuppliesCategoryFormModal extends Vue {
  @SuppliesCategoryNamespace.Getter(SuppliesCategoryGetter.suppliesCategory)
  suppliesCategory!: SuppliesCategoryViewModel;

  input = this.getDefaultInputValue();
  orderingListLoading = false;

  VUE_APP_API_BASE_HOST = process.env.VUE_APP_API_BASE_HOST;
  URL = URL;
  suppliesCategoryStatusOptions: DropdownOption[] = enumToDropdownOptions(
    BaseStatus
  );

  $refs!: {
    formRules: InstanceType<typeof ValidationProvider>;
    modal: InstanceType<typeof BModal>;
    oderingModal: InstanceType<typeof BModal>;
  };

  orderingList: ProductCategoryOrderNumber[] = [];

  @Watch('suppliesCategory')
  suppliesCategoryUpdated(): void {
    if (this.suppliesCategory) {
      this.input = {
        image: null,
        name: this.suppliesCategory.name as string,
        status: this.suppliesCategory.status as BaseStatus,
        metaDescription: this.suppliesCategory.metaDescription as string,
        orderingNumber: this.suppliesCategory.orderNumber
      };
    } else {
      this.input = this.getDefaultInputValue();
    }
  }

  openCreateModal(): void {
    dispatchSuppliesCategoryAction(
      SuppliesCategoryAction.clearSuppliesCategory
    );
    this.input = this.getDefaultInputValue();
    this.$refs.modal.show();
  }

  async openEditModal(id: number): Promise<void> {
    dispatchSuppliesCategoryAction(
      SuppliesCategoryAction.loadSuppliesCategory,
      id
    );
    this.$refs.modal.show();
  }

  getDefaultInputValue() {
    return {
      name: '',
      image: null,
      status: BaseStatus.Active,
      metaDescription: '',
      orderingNumber: 0
    };
  }

  add(): void {
    const client = new ApiClientFactory().suppliesCategoryClient();
    // const image: FileParameter = {
    //   data: this.input.image,
    //   fileName: (this.input.image as any).name
    // };

    client
      .create(
        this.input.name,
        this.input.metaDescription,
        this.input.orderingNumber
      )
      .then(() => {
        suppliesCategoryListDispatcher.load();
        this.$refs.modal.hide();
        // show toast
        this.$bvToast.toast('Thêm mới danh mục vật tư thành công', {
          title: 'Danh mục vật tư',
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        });
      });
  }

  edit() {
    const client = new ApiClientFactory().suppliesCategoryClient();
    const image: FileParameter | null = this.input.image
      ? {
          data: this.input.image,
          fileName: (this.input.image as any).name
        }
      : null;

    client
      .edit(
        this.suppliesCategory.id,
        this.input.name,
        this.input.metaDescription,
        this.input.orderingNumber,
        this.input.status
      )
      .then(() => {
        suppliesCategoryListDispatcher.load();
        this.$refs.modal.hide();
        // show toast
        this.$bvToast.toast('Chỉnh sửa danh mục vật tư thành công', {
          title: 'Danh mục vật tư',
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        });
      });
  }

  imageChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.input.image = event.target.files[0];
    }
  }
  submit(): void {
    debugger;
    this.$refs.formRules.validate().then((success) => {
      if (success) {
        if (this.suppliesCategory) {
          this.edit();
        } else {
          this.add();
        }
      }
    });
  }

  openOrderingModel() {
    this.$refs.oderingModal.show();
    this.orderingListLoading = true;

    const client = new ApiClientFactory().suppliesCategoryClient();
    client
      .getListByOrderNumber()
      .then((result) => {
        if (result) {
          this.orderingList = result.sort(
            (a, b) => a.orderNumber - b.orderNumber
          );
        }
      })
      .catch((err) => {
        this.$bvToast.toast('Tải danh sách thất bại', {
          title: 'Danh mục vật tư',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
      })
      .finally(() => {
        this.orderingListLoading = false;
      });
  }

  onSortOrderingSubmit(data) {
    this.orderingList.forEach((el, index) => {
      el.orderNumber = index + 1;
    });

    const client = new ApiClientFactory().suppliesCategoryClient();
    client
      .updateOrderNumber({
        suppliesCategoryOrderNumbers: this.orderingList
      } as UpdateSuppliesCategoryOrderNumbers)
      .then((result) => {
        if (result) {
          suppliesCategoryListDispatcher.load();
          this.$bvToast.toast('Lưu thành công', {
            title: 'Thứ tự danh mục vật tư',
            toaster: 'b-toaster-bottom-right',
            variant: 'success'
          });
        } else {
          this.$bvToast.toast('Lưu thất bại', {
            title: 'Thứ tự danh mục vật tư',
            toaster: 'b-toaster-bottom-right',
            variant: 'danger'
          });
        }
      })
      .catch((err) => {
        this.$bvToast.toast('Lưu thất bại', {
          title: 'Thứ tự danh mục vật tư',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
      })
      .finally(() => {
        this.orderingListLoading = false;
      });
  }
}
